import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../../styles/GlobalPages.css";
import { Link } from 'react-router-dom';
import { IonItem, IonLabel, IonList } from '@ionic/react';
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import { IonAlert } from '@ionic/react';
import Pregunta from '../../Imagenes/pregunta.png';
import PiePagina from '../../components/Navbar/PiePagina';
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import {
  personCircleOutline
} from 'ionicons/icons';
import { IonIcon } from '@ionic/react';


class DatosClienteTranslated extends React.Component {

  state = {
    nombreEmpresa: '',
    nombre: '',
    alert: false
  };



  componentDidMount() {
    const datosempresa = JSON.parse(localStorage.getItem("empresa"));
    const language = JSON.parse(localStorage.getItem("idioma"));
    i18next.changeLanguage(language);
    if (datosempresa == null) {

    } else {
      this.setState({ nombreEmpresa: datosempresa.nombreComercial });
    }
  }

  abrirAlert = () => {
    this.setState({ alert: !this.state.alert });
  }
  cerrarSesion = () => {
    this.abrirAlert();
  };

  render() {
    const { t } = this.props;

    const cliente = JSON.parse(localStorage.getItem('user'));

    if (cliente == null) {
      return (
        <div className="container h-100">
          <div className="nav-navegacion">
            <NavbarVariable datos={'/Home'} />
          </div>
          <div>
            <IonLabel className="titulo-pagina"> {process.env.REACT_APP_APP_NAME == 'Wikai' ? <IonIcon className="iconos-titulo" icon={personCircleOutline} /> : ''}{t('DatosCliente.titulo',)}</IonLabel>
          </div>
          <IonList className="datosCliente" >
            <IonItem className="datosClienteDescripcion">
              <FontAwesomeIcon icon="user" className="icon" />
              <IonLabel className="inputs"></IonLabel>
            </IonItem>
            <IonItem className="datosClienteDescripcion">
              <FontAwesomeIcon icon="user-plus" className="icon" />
              <IonLabel className="inputs"></IonLabel>
            </IonItem>
            <IonItem className="datosClienteDescripcion">
              <FontAwesomeIcon icon="id-card" className="icon" />
              <IonLabel className="inputs"></IonLabel>
            </IonItem>
            <IonItem className="datosClienteDescripcion">
              <FontAwesomeIcon icon="at" className="icon" />
              <IonLabel className="inputs"></IonLabel>
            </IonItem>
            <IonItem className="datosClienteDescripcion">
              <FontAwesomeIcon icon="phone" className="icon" />
              <IonLabel id="telefono" name="telefono" className="inputs"></IonLabel>
            </IonItem>
            <IonItem className="datosClienteDescripcion">
              <FontAwesomeIcon icon="mobile-screen-button" className="icon icon-movil" />
              <IonLabel className="inputs"></IonLabel>
            </IonItem>
            <IonItem className="datosClienteDescripcion1">
              <FontAwesomeIcon icon="key" className="icon" />
              <IonLabel className="btn-opc inputs"><Link to="/contrasenia" id="cambiarClave" name="cambiarClave" className="cambiopass" >{t('DatosCliente.cambiopass',)}</Link></IonLabel>
            </IonItem>
            {process.env.REACT_APP_APP_NAME != 'TUO' ?
              <IonItem className="datosClienteDescripcion1">
                <FontAwesomeIcon icon="circle-info" className="icon" />
                <IonLabel className="btn-opc inputs"><Link to="/AcercaDe" className="input">{t('DatosCliente.acerca',)}</Link></IonLabel>
              </IonItem> : ''}
          </IonList>
          <button onClick={() => this.cerrarSesion()} className="btn-rojo-xl" >
            <FontAwesomeIcon icon="power-off" className="icon-red" />
            <IonLabel className="" >{t('DatosCliente.cerrar',)}</IonLabel>
          </button>
          <PiePagina />
          <IonAlert
            isOpen={this.state.alert}
            cssClass='correcto info'
            header={t('DatosCliente.infoCerrar',)}
            message={`<img src="${Pregunta}" alt="error" className="errorimg">`}
            buttons={[{
              text: [`${t('Aceptar.cance',)}`],
              cssClass: 'info',
            },
            {
              text: [`${t('Aceptar.acep',)}`],
              handler: () => {
                localStorage.clear();
                window.location = '/Login';
              }
            }
            ]}
          />

        </div>
      );
    }
    if (cliente.personaFisica === 1) {
      return (

        <div className="container h-100">
          <div className="nav-navegacion">
            <NavbarVariable datos={'/Home'} />
          </div>
          <div>
            <IonLabel className="titulo-pagina"> {process.env.REACT_APP_APP_NAME == 'Wikai' ? <IonIcon className="iconos-titulo" icon={personCircleOutline} /> : ''}{t('DatosCliente.titulo',)}</IonLabel>
          </div>
          <IonList className="datosCliente" >
            <IonItem className="datosClienteDescripcion">
              <FontAwesomeIcon icon="user" className="icon" />
              <IonLabel className="inputs">{cliente.nombre}</IonLabel>
            </IonItem>
            <IonItem className="datosClienteDescripcion">
              <FontAwesomeIcon icon="user-plus" className="icon" />
              <IonLabel className="inputs">{cliente.apellidos}</IonLabel>
            </IonItem>
            <IonItem className="datosClienteDescripcion">
              <FontAwesomeIcon icon="id-card" className="icon" />
              <IonLabel className="inputs">{cliente.cif}</IonLabel>
            </IonItem>
            <IonItem className="datosClienteDescripcion">
              <FontAwesomeIcon icon="at" className="icon" />
              <IonLabel className="inputs">{cliente.email}</IonLabel>
            </IonItem>
            <IonItem className="datosClienteDescripcion">
              <FontAwesomeIcon icon="phone" className="icon" />
              <IonLabel id="telefono" name="telefono" className="inputs">{cliente.telefono}</IonLabel>
            </IonItem>
            <IonItem className="datosClienteDescripcion">
              <FontAwesomeIcon icon="mobile-screen-button" className="icon icon-movil" />
              <IonLabel className="inputs">   {cliente.movil}</IonLabel>
            </IonItem>
            <IonItem className="datosClienteDescripcion">
              <FontAwesomeIcon icon="key" className="icon" />
              <IonLabel className="btn-opc inputs"><Link to="/contrasenia" id="cambiarClave" name="cambiarClave" className="" >{t('DatosCliente.cambiopass',)}</Link></IonLabel>
            </IonItem>
            {process.env.REACT_APP_APP_NAME != 'TUO' ?
              <IonItem className="datosClienteDescripcion">
                <FontAwesomeIcon icon="circle-info" className="icon" />
                <IonLabel className="btn-opc inputs"><Link to="/AcercaDe" className="input">{t('DatosCliente.acerca',)} {this.state.nombreEmpresa}</Link></IonLabel>
              </IonItem> : ''}
          </IonList>
          <button onClick={() => this.cerrarSesion()} className="btn-rojo-xl" >
            <FontAwesomeIcon icon="power-off" className="icon-red" />
            <IonLabel className="" >{t('DatosCliente.cerrar',)}</IonLabel>
          </button>
          <PiePagina />
          <IonAlert
            isOpen={this.state.alert}
            cssClass='correcto info'
            header={t('DatosCliente.infoCerrar',)}
            message={`<img src="${Pregunta}" alt="error" className="errorimg">`}
            buttons={[{
              text: [`${t('Aceptar.cance',)}`],
              cssClass: 'info',
            },
            {
              text: [`${t('Aceptar.acep',)}`],
              handler: () => {
                localStorage.clear();
                window.location = '/Login';
              }
            }
            ]}
          />
        </div>
      );
    } else {
      return (
        <div className="container h-100">
          <div className="nav-navegacion">
            <NavbarVariable datos={'/Home'} />
          </div>
          <div>
            <IonLabel className="titulo-pagina"> {process.env.REACT_APP_APP_NAME == 'Wikai' ? <IonIcon className="iconos-titulo" icon={personCircleOutline} /> : ''}{t('DatosCliente.titulo',)}</IonLabel>
          </div>
          <IonList className="datosCliente" >
            <IonItem className="datosClienteDescripcion">
              <FontAwesomeIcon icon="user" className="icon" />
              <IonLabel className="inputs">{cliente.nombre_completo}</IonLabel>
            </IonItem>
            <IonItem className="datosClienteDescripcion">
              <FontAwesomeIcon icon="user" className="icon" />
              <IonLabel className="inputs">{cliente.cif}</IonLabel>
            </IonItem>
            <IonItem className="datosClienteDescripcion">
              <FontAwesomeIcon icon="at" className="icon" />
              <IonLabel className="inputs">{cliente.email}</IonLabel>
            </IonItem>
            <IonItem className="datosClienteDescripcion">
              <FontAwesomeIcon icon="phone" className="icon" />
              <IonLabel id="telefono" name="telefono" className="inputs">{cliente.telefono}</IonLabel>
            </IonItem>
            <IonItem className="datosClienteDescripcion">
              <FontAwesomeIcon icon="mobile-screen-button" className="icon" />
              <IonLabel className="inputs"> {cliente.movil}</IonLabel>
            </IonItem>
            <IonItem className="datosClienteDescripcion">
              <FontAwesomeIcon icon="key" className="icon" />
              <IonLabel className="btn-opc inputs"><Link to="/contrasenia" id="cambiarClave" name="cambiarClave" className="" >{t('DatosCliente.cambiopass',)}</Link></IonLabel>
            </IonItem>
            {process.env.REACT_APP_APP_NAME != 'TUO' ?
              <IonItem className="datosClienteDescripcion">
                <FontAwesomeIcon icon="circle-info" className="icon" />
                <IonLabel className="btn-opc inputs"><Link to="/AcercaDe" className="input">{t('DatosCliente.acerca',)} {this.state.nombreEmpresa}</Link></IonLabel>
              </IonItem> : ''}
          </IonList>
          <button onClick={() => this.cerrarSesion()} className="btn-rojo-xl" >
            <FontAwesomeIcon icon="power-off" className="icon-red" />
            <IonLabel className="" >{t('DatosCliente.cerrar',)}</IonLabel>
          </button>
          <PiePagina />
          <IonAlert
            isOpen={this.state.alert}
            cssClass='correcto info'
            header={t('DatosCliente.infoCerrar',)}
            message={`<img src="${Pregunta}" alt="error" className="errorimg">`}
            buttons={[{
              text: [`${t('Aceptar.cance',)}`],
              cssClass: 'info',
            },
            {
              text: [`${t('Aceptar.acep',)}`],
              handler: () => {
                localStorage.clear();
                window.location = '/Login';
              }
            }
            ]}
          />
        </div>
      );
    }
  }
};
const DatosCliente = withTranslation('common')(DatosClienteTranslated)
export default DatosCliente;
