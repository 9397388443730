import React from "react";
import ConsumoActual from '../../components/Home/ConsumoActual';
import "../../styles/GlobalPages.css";
import { withTranslation } from "react-i18next";
import ConsumoMensual from "../../components/Consumo/ConsumoMensual";
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import api from '../../components/servicios/api';
import { barChartOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
let tarifa;
class ConsumoTranslated extends React.Component {

  state = {
    consumo: true,
    mensual: false,
  };


  async componentDidMount() {
    const appConf = JSON.parse(localStorage.getItem('appconf'));

    if (appConf.grafico_consumo == 1) {
      const cliente = JSON.parse(localStorage.getItem('user'));
      await api.Clientes.serviciosActivos(cliente.usuario).then(
        ({ data }) => (tarifa = data)
      );
      if (Array.isArray(tarifa)) {
        this.setState({ mensual: false, consumo: true });
      } else {
        this.setState({ mensual: true, consumo: false });
      }
    } else {
      this.setState({ mensual: true, consumo: false });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <div className="container h-100">
          <div className="nav-navegacion">
            <NavbarVariable datos={'/Home'} />
          </div>
          <div className={this.state.consumo ? "titulo-pagina-margen" : "titulo-pagina"}>
            <span className=""> {process.env.REACT_APP_APP_NAME == 'Wikai' ? <IonIcon className="iconos-titulo" icon={barChartOutline} /> : ''}{t('Consumo.titulo',)}</span>
          </div>
          {this.state.consumo && <ConsumoActual />}
          {this.state.mensual && <ConsumoMensual />}
        </div>


      </React.Fragment >
    );
  }
};
const Consumo = withTranslation('common')(ConsumoTranslated)
export default Consumo;