import React from 'react';
import PageLoading from '../../components/PantallaCarga/PageLoading';
import PageError from '../../components/PantallaCarga/PageError';
import Pageinfo from '../../components/PantallaCarga/Pageinfo';
import api from '../../components/servicios/api';
import "../../styles/GlobalPages.css";
import MiniLoader from "../../components/PantallaCarga/MiniLoader";
import ContratosList from '../../components/Contratos/ContratosList';
import { withTranslation } from "react-i18next";
import PiePagina from '../../components/Navbar/PiePagina';
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import { readerOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

let contratosAsociados = [];
let contratos = [];
let userData = '';

class ContratosTranslated extends React.Component {

  state = {
    loading: false,
    error: false,
    datos: undefined,
    sinDatos: false

  };

  componentDidMount() {
    contratos = [];
    contratosAsociados = [];
    userData = '';
    this.fetchData();

  }

  htmlEntities(str) {
    const patron = /,/g;
    return String(str).replace(patron, ' - ');

  }
  htmlEntitiesRaya(str) {
    const patron = /-/g;
    return String(str).replace(patron, ' ');

  }


  fetchData = async () => {
    this.setState({ loading: true, error: null });

    try {
      const cliente = JSON.parse(localStorage.getItem('user'));
      await api.Clientes.contratos(cliente.usuario).then(
        ({ data }) => (userData = data)
      );

      if (Array.isArray(userData)) {
        for (let i = 0; i < userData.length; i++) {
          if (userData[i].contrato_asociado != 0 && userData[i].contrato_asociado != userData[i].id) {
            contratosAsociados.push(userData[i]);
          } else {
            contratos.push(userData[i]);
          }

        }

        for (let i = 0; i < contratos.length; i++) {
          if (contratos[i].telefonos_contrato === null) {
            contratos[i].telefonos_contrato = (contratos[i].servicios.map((dato) =>
              dato.telefono_contrato_asociado));
          }
        };

        for (let i = 0; i < contratos.length; i++) {
          if (contratos[i].telefonos_contrato) {
            contratos[i].telefonos_contrato = this.htmlEntities(contratos[i].telefonos_contrato);
          }
        }

        for (let i = 0; i < contratos.length; i++) {

          if (contratos[i].importe_remesar !== null) {
            contratos[i].descuento_total = this.htmlEntitiesRaya(contratos[i].descuento_total);
            contratos[i].importe_remesar = (contratos[i].importe_remesar - contratos[i].descuento_total);
            contratos[i].importe_remesar = parseFloat(contratos[i].importe_remesar).toFixed(2);
          }
        }

        for (let i = 0; i < contratosAsociados.length; i++) {

          if (contratosAsociados[i].importe_remesar !== null) {
            contratosAsociados[i].descuento_total = this.htmlEntitiesRaya(contratosAsociados[i].descuento_total);
          }
        }

        for (let i = 0; i < contratosAsociados.length; i++) {

          if (contratosAsociados[i].importe_remesar !== null) {
            contratosAsociados[i].importe_remesar = (contratosAsociados[i].importe_remesar - contratosAsociados[i].descuento_total);
            contratosAsociados[i].importe_remesar = parseFloat(contratosAsociados[i].importe_remesar).toFixed(2);
          }
        }

        this.setState({ loading: false, datos: contratos });
      } else if (userData == "sin datos" || userData == "No se han encontrado datos para el modelo <b>contratos_app</b>") {
        this.setState({ loading: false, sinDatos: true });
      } else {
        this.setState({ loading: false, error: true });
      }


    } catch (error) {
      this.setState({ loading: false, error: true });
    }
  };

  render() {
    const { t } = this.props;
    if (this.state.loading) {
      return <PageLoading />;
    }

    if (this.state.error) {
      return <PageError />;
    }

    if (this.state.sinDatos) {
      return (
        <Pageinfo datos={'Contratos'} />
      )
    }
    return (
      <div className="container h-100">
        <div className="nav-navegacion">
          <NavbarVariable datos={'/Home'} />
        </div>
        <div className="titulo-pagina">
          <span>{process.env.REACT_APP_APP_NAME == 'Wikai' ? <IonIcon className="iconos-titulo" icon={readerOutline} /> : ''} {t('Contratos.titulo',)}</span>
        </div>
        <div className="lista">
          <ContratosList contratos={contratos} asociados={contratosAsociados} />
          {this.state.loading && <MiniLoader />}
        </div>
        <PiePagina />
      </div>

    );

  }
}
const Contratos = withTranslation('common')(ContratosTranslated)
export default Contratos;