import React from 'react';
import api from '../../components/servicios/api';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import PageError from "../../components/PantallaCarga/PageError";
import MiniLoader from "../../components/PantallaCarga/MiniLoader";
import DocumentosFirma from '../../components/Documentos/DocumentosFirma';
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import { withTranslation } from "react-i18next";
import Alertas from '../../components/servicios/Alertas';
import Pageinfo from '../../components/PantallaCarga/Pageinfo';
import PiePagina from '../../components/Navbar/PiePagina';
import "../../styles/GlobalPages.css";
import { receiptOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';


let userData;
let documentos = [];

class DocumentosTranslated extends React.Component {
    state = {
        loading: false,
        error: false,
        datos: undefined,
        sinDatos: false

    };
    componentDidMount() {
        documentos = [];
        this.fetchData();
    }


    fetchData = async () => {
        this.setState({ loading: true, error: null });
        const idioma = this.props.i18n.language;
        try {
            const cliente = JSON.parse(localStorage.getItem('user'));
            await api.Clientes.documentosFirma(`&cliente=${cliente.usuario}`).then(
                ({ data }) => (userData = data)
            );

            if (Array.isArray(userData)) {
                for (let i = 0; i < userData.length; i++) {
                    documentos.push(userData[i]);
                }
                this.setState({ loading: false, datos: userData });
            } else if (userData == "sin datos" || userData == "No se han encontrado datos para el modelo <b>get_documentos_firmar</b>") {
                this.setState({ loading: false, sinDatos: true });
            } else {
                this.setState({ loading: false, error: true });
                Alertas.sinConexion(idioma);
            }
        } catch (error) {
            this.setState({ loading: false, error: error });
        }
    };

    render() {
        const { t } = this.props;

        if (this.state.loading === true && !this.state.datos) {
            return <PageLoading />;
        }

        if (this.state.error) {
            return <PageError />;
        }
        if (this.state.sinDatos) {
            return (
                <Pageinfo datos={'Documentos'} />
            )
        }
        return (
            <div className="container h-100">
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Home'} />
                </div>
                <div className="titulo-pagina">
                    <span className="">{process.env.REACT_APP_APP_NAME == 'Wikai' ? <IonIcon className="iconos-titulo" icon={receiptOutline} /> : ''}{t('Documentos.titulo',)}</span>
                </div>
                <div className="lista">
                    <ul className="list-unstyled">
                        {documentos.map(documento => {
                            return (
                                <li key={documento.id}>
                                    <DocumentosFirma
                                        id={documento.id}
                                        descripcion={documento.descripcion}
                                        url={documento.url}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                    {this.state.loading && <MiniLoader />}
                </div>
                <PiePagina />
            </div>
        );
    };
}
const Documentos = withTranslation('common')(DocumentosTranslated)
export default Documentos;