import React from 'react';
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import api from '../../components/servicios/api';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import PageError from "../../components/PantallaCarga/PageError";
import MiniLoader from "../../components/PantallaCarga/MiniLoader";
import Paginacion from '../../components/Paginacion';
import { withTranslation } from "react-i18next";
import Pageinfo from '../../components/PantallaCarga/Pageinfo';
import PiePagina from '../../components/Navbar/PiePagina';
import { bookOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

import "../../styles/Noticias.css";

let userData;
let noticias = [];

class NoticiasTranslated extends React.Component {
    state = {
        loading: false,
        error: false,
        datos: undefined,
        allNoticias: [],
        currentNoticia: [],
        currentPage: null,
        totalPages: null,
        sinDatos: false,
        noticia1: false,

    };
    componentDidMount() {
        noticias = [];
        this.fetchData();
        const { data: allNoticias = [] } = noticias;
        this.setState({ allNoticias: noticias });

    }

    onPageChanged = data => {
        const { allNoticias } = this.state;
        const { currentPage, totalPages, pageLimit } = data;
        const offset = (currentPage - 1) * pageLimit;
        const currentNoticia = allNoticias.slice(offset, offset + pageLimit);

        this.setState({ currentPage, currentNoticia, totalPages });
    }
    convertDateFormat(string) {
        return string.split('-').reverse().join('/');

    }

    fetchData = async () => {
        this.setState({ loading: true, error: null });

        try {
            await api.Clientes.noticias().then(
                ({ data }) => (userData = data)
            );


            if (Array.isArray(userData)) {
                for (let i = 0; i < userData.length; i++) {
                    if (userData[i].activo == 1) {
                        noticias.push(userData[i]);
                    }
                    console.log(noticias.length);
                }
                for (let i = 0; i < noticias.length; i++) {
                    if (noticias[i].fecha) {
                        noticias[i].fecha = this.convertDateFormat(noticias[i].fecha);
                    }
                    console.log(noticias.length);
                }
                this.setState({ loading: false, datos: userData });
                if (noticias.length == 0) {
                    this.setState({ sinDatos: true });
                }

            } else if (userData == "sin datos" || userData == "No se han encontrado datos para el modelo <b>noticias_app</b>") {
                this.setState({ loading: false, sinDatos: true });
            } else {
                this.setState({ loading: false, error: true });
            }
        } catch (error) {
            this.setState({ loading: false, error: error });
        }
    };

    render() {
        const { t } = this.props;

        if (this.state.loading === true && !this.state.datos) {
            return <PageLoading />;
        }

        if (this.state.error) {
            return <PageError />;
        }
        if (this.state.sinDatos) {
            return <Pageinfo datos={'Noticias'} />
        }

        const { allNoticias, currentNoticia, currentPage, totalPages } = this.state;
        const totalNoticias = allNoticias.length;
        return (
            <div className="container h-100">
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Home'} />
                </div>
                <div className="titulo-pagina">
                    <span className="">{process.env.REACT_APP_APP_NAME == 'Wikai' ? <IonIcon className="iconos-titulo" icon={bookOutline} /> : ''}{t('Noticias.titulo',)}</span>
                </div>
                <div className="">
                    <ul className="list-unstyled">
                        {currentNoticia.map(noticia => {
                            return (
                                <li className="noticias-list-li" key={noticia.id}>
                                    <div className="noticias-list">
                                        <div className="noticias-titulo">
                                            {noticia.titulo}
                                        </div>
                                        <div className="noticias-descripcion">
                                            <span className="noticias-descripcion" dangerouslySetInnerHTML={{ __html: noticia.descripcion }} />
                                        </div>
                                        <div className="noticias-fecha">
                                            {noticia.fecha}
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="paginacion">
                    <Paginacion totalRecords={totalNoticias} pageLimit={2} pageNeighbours={1} onPageChanged={this.onPageChanged} />
                </div>
                <div className="num-pagina">
                    {currentPage && (
                        <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                            {t('Noticias.pagina',)} <span className="font-weight-bold">{currentPage}</span> / <span className="font-weight-bold">{totalPages}</span>
                        </span>
                    )}
                </div>
                {this.state.loading && <MiniLoader />}
                <PiePagina />
            </div>
        );
    };

}
const Noticias = withTranslation('common')(NoticiasTranslated)
export default Noticias;