import React from "react";
import PageLoading from "../../components/PantallaCarga/PageLoading";
import PageError from "../../components/PantallaCarga/PageError";
import MiniLoader from "../../components/PantallaCarga/MiniLoader";
import FacturasList from "../../components/Facturas/FacturasList";
import api from "../../components/servicios/api";
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import { withTranslation } from "react-i18next";
import PiePagina from '../../components/Navbar/PiePagina';
import { Keyboard } from '@capacitor/keyboard';
import { isPlatform } from '@ionic/react';
import { newspaperOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

import "../../styles/GlobalPages.css";
let arrayYear = [];
let userData;
let f = new Date();

let d = new Date(f.getFullYear(), 1);
arrayYear.push(d.getFullYear());

for (let i = 0; i < 3; i++) {
  arrayYear.push(d.getFullYear() - 1);
  d.setFullYear(d.getFullYear() - 1);
}



class FacturasTranslated extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anio: arrayYear[0],
      loading: true,
      error: null,
      datos: undefined,
      sinDatos: false,
    }
    this.handleChange = this.handleChange.bind(this);

  };
  componentDidMount() {
    if (isPlatform('ios') && !isPlatform('mobileweb')) {
      Keyboard.setAccessoryBarVisible({ isVisible: true });
    }
    this.fetchData();
  }

  handleChange = async (e) => {
    await this.setState({ anio: e.target.value });
    this.fetchData();
  };


  fetchData = async () => {
    this.setState({ loading: true, error: null, datos: undefined });
    userData = '';
    try {
      const cliente = JSON.parse(localStorage.getItem('user'));
      await api.Clientes.facturas(cliente.usuario, this.state.anio).then(
        ({ data }) => (userData = data)
      );
      if (Array.isArray(userData)) {
        this.setState({ loading: false, datos: userData, sinDatos: false });
      } else if (userData == "sin datos" || "No se han encontrado datos para el modelo <b>facturas_app</b>" == userData) {
        this.setState({ loading: false, sinDatos: true, datos: false });
      } else {
        this.setState({ loading: false, error: true });
      }
    } catch (error) {
      this.setState({ loading: false, error: true });
    }
  };

  render() {
    const { t } = this.props;
    if (this.state.loading) {
      return <PageLoading />;
    }

    if (this.state.error) {
      return <PageError />;
    }

    return (
      <div className="container h-100">
        <div className="nav-navegacion">
          <NavbarVariable datos={'/Home'} />
        </div>
        <div className="titulo-pagina">
          <span>{process.env.REACT_APP_APP_NAME == 'Wikai' ? <IonIcon className="iconos-titulo" icon={newspaperOutline} /> : ''}{t('Facturas.titulo',)}</span>
        </div>
        <div className="select-anio">
          <span>{t('Facturas.anio',)}</span>
          <select value={this.state.anio} onChange={this.handleChange} className="">
            {arrayYear.map((dato) =>
              <option key={dato.id} value={dato.value}>{dato}</option>
            )}
          </select>
        </div>
        <div className={this.state.sinDatos ? 'lista' : 'hidden'}>
          <span className="no-coment">{t('Facturas.info',)}</span>
        </div>
        <div className={this.state.sinDatos ? 'hidden' : 'lista'}>
          {this.state.datos && <FacturasList facturas={this.state.datos} />}
        </div>
        {this.state.loading && <MiniLoader />}
        <PiePagina />
      </div>
    );
  }
}
const Facturas = withTranslation('common')(FacturasTranslated)
export default Facturas;
